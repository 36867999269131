import { ChildrenLayoutProps } from 'types';
import { spacing } from '@/lib/helpers/spacing';
import { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { omit } from 'sdk';

export interface DividerProps
  extends ChildrenLayoutProps,
    HTMLAttributes<HTMLDivElement> {}

export default function Divider({
  m = 0,
  mb = 0,
  ml = 0,
  mr = 0,
  mt = 0,
  p = 0,
  pb = 0,
  pl = 0,
  pr = 0,
  pt = 0,
  className,
  ...props
}: DividerProps) {
  const dynamicStyles = {
    ...(!!m && { margin: spacing.padding * m }),
    ...(!!mb && { marginBottom: spacing.padding * mb }),
    ...(!!ml && { marginLeft: spacing.padding * ml }),
    ...(!!mr && { marginRight: spacing.padding * mr }),
    ...(!!mt && { marginTop: spacing.padding * mt }),
    ...(!!p && { padding: spacing.padding * p }),
    ...(!!pb && { paddingBottom: spacing.padding * pb }),
    ...(!!pl && { paddingLeft: spacing.padding * pl }),
    ...(!!pr && { paddingRight: spacing.padding * pr }),
    ...(!!pt && { paddingTop: spacing.padding * pt }),
  };

  return (
    <div
      style={dynamicStyles}
      className={classNames('w-full h-[2px] bg-grey-300', className)}
      {...omit(props, ['className'])}
    />
  );
}
